svg {
	display: block;
}

#loading-container {
	-webkit-text-size-adjust: 100%;
	font-family: acumin-pro, sans-serif;
	--text-opacity: 1;
	color: rgba(255, 255, 255, var(--text-opacity));
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.01em;
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: #e2e8f0;
	display: flex;
	align-items: center;
	justify-content: center;
	--bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--bg-opacity));
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	z-index: 50;
	flex-direction: column;

	/* transition: opacity 0.3s ease-in-out; */
}

#loading-container.hidden {
	opacity: 0;
	pointer-events: none
}

#reason {
	-webkit-text-size-adjust: 100%;
	font-family: acumin-pro, sans-serif;
	--text-opacity: 1;
	color: rgba(255, 255, 255, var(--text-opacity));
	line-height: 160%;
	--bg-opacity: 1;
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: #e2e8f0;
	margin: 0;
	margin-top: -10px;
	font-size: 0.935rem;
	font-style: italic;
	font-weight: 300;
	letter-spacing: 0.05em;
	opacity: 0.5;
	padding-top: 10rem;
	position: absolute;
	white-space: pre-line;
	text-align: center;
	max-width: 380px;
}

.fill {
	-webkit-animation: swoop-data-v-6737ac94 1s ease-in-out infinite;
	animation: swoop-data-v-6737ac94 1s ease-in-out infinite;
}

svg {
	opacity: .8;
}

/*! CSS Used keyframes */
@-webkit-keyframes swoop-data-v-6737ac94 {
	0% {
		transform: translateX(-100px);
	}

	to {
		transform: translateX(calc(100% + 20px));
	}
}

@keyframes swoop-data-v-6737ac94 {
	0% {
		transform: translateX(-100px);
	}

	to {
		transform: translateX(calc(100% + 20px));
	}
}
