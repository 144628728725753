@font-face {
	font-family: 'Arcade Interlaced';
	src: url('fonts/ArcadeInterlaced.woff2') format('woff2'),
			url('fonts/ArcadeInterlaced.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Arcade Normal';
	src: url('fonts/ArcadeNormal.woff2') format('woff2'),
			url('fonts/ArcadeNormal.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Arcade Rounded';
	src: url('fonts/ArcadeRounded.woff2') format('woff2'),
			url('fonts/ArcadeRounded.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Pixel Splitter Bold';
	src: url('fonts/PixelSplitter-Bold.woff2') format('woff2'),
			url('fonts/PixelSplitter-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

