

#menu * {
	/* transition: all 1s cubic-bezier(0.52,0,0.29,0.99); */
}

#menu .leaderboard,
#menu .leaderboard * {
	/* transition: all 1s cubic-bezier(0.27,0.46,0.07,0.99);
	transition-delay: 0.1s; */
}

#menu .laderboardHeader {
	display: flex;
	height: 30px;
}
#menu .leaderboardCTALabel {
	position: absolute;
	width: 100vw;
	margin: 0px;
	color: white;
	font-size: 19px;
	font-family: Pixel Splitter Bold;
	z-index: 9;
	text-shadow: 0 0 15px black;
}
#menu .leaderboardTitleLabel {
	position: absolute;
	width: 100vw;
	left: 100vw;
	margin: 0px;
	color: white;
	font-family: Pixel Splitter Bold;
	z-index: 9;
	text-shadow: 0 0 15px black;
}
#menu .leaderboardPickerWrapper {
	position: absolute;
	left: 0;
	width: 100%;
	height: 30px;
	bottom: calc(20px + 30px);
	display: flex;
	align-items: center;
	justify-content: center;
}
#menu .leaderboardPicker {
	height: 100%;
	border-radius: 60px;
	background: rgba(0,0,0,0.8);
	backdrop-filter: saturate(180%);
	/* backdrop-filter: saturate(180%) blur(5px); */
	box-shadow: black 0px 0px 65px;
	display: flex;
	padding: 10px;
}
#menu .leaderboardOption {
	color: white;
	border-radius: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 10px;
	cursor: pointer;
}
#menu .leaderboardOptionActive {
	color: black;
	background-color: aqua;
}
#menu .leaderboardOptionLabel {
	margin: 0;
	font-family: 'Pixel Splitter Bold';
}
#menu .leaderboardTable {
	color: white;
	font-family: 'Pixel Splitter Bold';
	column-fill: balance;
	table-layout: auto;
	width: 100%;
	padding-top: 4px;
	text-align: left;
}
#menu.leaderboard-expanded .leaderboardTable {
	padding-top: 7.5px;
}
#menu .leaderboardTableHeader {
	text-align: center;
	font-size: 16px;
	opacity: 0.7;
}
#menu .leaderboardTableData {
	text-align: center;
}

#menu {

}

#menu:not(.leaderboard-expanded) {
	
}

#menu.leaderboard-expanded {
	
}

/* menu animatedStyles */

#menu:not(.visible) {
	transform: translateY(200px);
	opacity: 0;
}
#menu.visible {
	transform: translateY(0px);
	opacity: 1;
}

/* menuStyles */

#menu .menuArea {
	/* filter: blur(0px); */
	transform: translateY(0px);
	opacity: 1;
}

#menu.leaderboard-expanded .menuArea,
#menu.matchmaking .menuArea {
	/* filter: blur(100px); */
	transform: translateY(200px);
	opacity: 0;
}

/* #menu {
	opacity: 1
}

#menu.leaderboard-expanded,
#menu.matchmaking {
	opacity: 0;
} */

/* prizeStyles */

#menu.matchmaking .prizeArea {
	opacity: 1;
}

#menu .prizeArea {
	/* filter: blur(0px); */
	transform: translateY(0px);
}
#menu.leaderboard-expanded .prizeArea,
#menu.matchmaking .prizeArea {
	/* filter: blur(100px); */
	transform: translateY(200px);
}

#menu .prizeArea {
	opacity: 1
}

#menu.leaderboard-expanded .prizeArea,
#menu.matchmaking .prizeArea {
	opacity: 0;
}

/* matchmakingStyles */

#menu:not(.matchmaking) .matchmakingArea {
	/* filter: blur(100px); */
	transform: translateY(200px);
	opacity: 0;
}

#menu.matchmaking .matchmakingArea {
	/* filter: blur(0px); */
	transform: translateY(0px);
	opacity: 1;
}

/* leaderboardStyles */

#menu.leaderboard-expanded .leaderboard {
	/* bottom: calc(100vh - 65px); */
	transform: translateY(calc(-100vh + 65px)) !important;
	
}

#menu:not(.leaderboard-expanded) .leaderboard {
	/* bottom: 104px; */
	transform: translateY(-69px) !important;

	
}

#menu.matchmaking .leaderboard {
	opacity: 0;
}
#menu:not(.matchmaking) .leaderboard {
	opacity: 1;
}

/* leaderboardAreaStyle */

#menu.leaderboard-expanded .leaderboardArea {
	top: 40px;
}

#menu:not(.leaderboard-expanded) .leaderboardArea {
	top: 30px;
}

/* leaderboardTitleStyle */

#menu.leaderboard-expanded .leaderboardTitle {
	/* left: 0vw; */
	transform: translateX(0);
}

#menu:not(.leaderboard-expanded) .leaderboardTitle {
	/* left: 100vw; */
	transform: translateX(100vw);
}

/* leaderboardCTAStyle */

#menu:not(.leaderboard-expanded) .leaderboardCTA {
	/* left: 0vw; */
	transform: translateX(0);
}

#menu.leaderboard-expanded .leaderboardCTA {
	/* left: -100vw; */
	transform: translateX(-100vw);
}

/* leaderboardTableHeader */

#menu:not(.leaderboard-expanded) .leaderboardTableHeader {
	display: none;
}

#menu.leaderboard-expanded .leaderboardTableHeader {
	display: table-cell;
}


/* 
#menu:not(.leaderboard-expanded) .leaderboardHeader {
	transform: translateX(0);
}

#menu.leaderboard-expanded .leaderboardHeader {
	transform: translateX(-100%);
} */

/* leaderboardPickerSyle */

#menu.leaderboard-expanded .leaderboardPicker {
	margin-bottom: 10em;
}

#menu:not(.leaderboard-expanded) .leaderboardPicker {
	margin-bottom: -1000px;
}

/* headerArea */

#menu .headerArea {
	position: absolute;
	left: 0;
	width: 100%;
	top: calc(-100vh + 30px);
	display: flex;
}

#menu .headerArea .online {
	margin-top: 8px;
	display: flex;
	
	justify-content: center;
	width: auto;
}

#menu .headerArea .buttons {
	filter: drop-shadow(0 0 65px black);
	right: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

#menu .headerArea .buttons > div {
	margin-bottom: 5px !important; 
}